import React from "react";
import { Form, Input, Button, Spin } from "antd";
import { SvgIcon } from "../../../components/common";

// Style
import "./index.scss";
import { showAlert } from "../../../common/alert-messages";
import { connect } from "react-redux";
import {
  createLoadingSelector,
  createResetPasswordAction,
  resetStoreState,
} from "../../../redux/actions";
import {
  CREATE_RESET_PASSWORD,
  CREATE_RESET_PASSWORD_RESET,
} from "../../../redux/actions/type";
import { componentPaths } from "../../../constants/path-constants";

import Logo from '../../../assets/images/logo.png';

class CreatenewPasswordView extends React.Component {
  componentDidUpdate() {
    if (this.props.successMessage) {
      showAlert.success(this.props.successMessage);
      this.props.resetStoreState(CREATE_RESET_PASSWORD_RESET);
      this.props.history.push(componentPaths.login);
    }
  }

  onFinish = (values) => {
    const requestObject = {
      activation_salt: this.props.match.params.id,
      new_pwd: values.password,
      confirm_pwd: values.confirmPassword,
    };

    this.props.createResetPasswordAction(requestObject);
  };

  render() {
    return (
      <Spin
        spinning={this.props.isFetchingData}
        size="large"
        className="mx-auto"
      >
        <div className="newpassword-wrapper">
          <div className="newpassword-left">
            <div>
              <div className="center-content">
                <img className="login-logo" src={Logo} alt="Logo" />
                <p className="mt-3 mb-0">Tekweld customer portal</p>
              </div>
            </div>
          </div>
          <div className="newpassword-right">
            <div className="login-form-wrapper">
              <div className="login-form-inner">
                <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{ remember: false }}
                  onFinish={this.onFinish}
                >
                  <div>
                    <h1 className="form-title mb-2">Create new password.</h1>
                  </div>
                  <p className="mb-0">
                    Your password shouldn't contain part of your first, last or
                    username, <b>It should contain 10 characters minimum</b>,
                    with at least 3 of the following:
                  </p>
                  <ul className="hint-list">
                    <li>Lower case letter</li>
                    <li>Upper case letter</li>
                    <li>Number (0-9)</li>
                    <li>Symbol {"(e.g. !@#$%^&*)"}</li>
                  </ul>
                  <Form.Item
                    name="password"
                    className="mb-3"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                      {
                        min: 4,
                        message: "Password must be minimum 4 characters!",
                      },
                      {
                        max: 40,
                        message: "Password must be maximum 40 characters!",
                      },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      prefix={
                        <SvgIcon
                          name="lock"
                          viewbox="0 0 12.4 16.4"
                          width="15px"
                        />
                      }
                      placeholder="New password"
                    />
                  </Form.Item>
                  <Form.Item
                    name="confirmPassword"
                    className="mb-1"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      size="large"
                      prefix={
                        <SvgIcon
                          name="lock"
                          viewbox="0 0 12.4 16.4"
                          width="15px"
                        />
                      }
                      placeholder="Confirm new password"
                    />
                  </Form.Item>
                  <Form.Item className="mb-1">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                      Save password
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    );
  }
}

const loadingSelector = createLoadingSelector([CREATE_RESET_PASSWORD]);

const mapStateToProps = (state) => ({
  successMessage: state.createResetPasswordMessage,
  isFetchingData: loadingSelector(state),
});

export default connect(mapStateToProps, {
  resetStoreState,
  createResetPasswordAction,
})(CreatenewPasswordView);
