/* eslint-disable max-len */
import React from "react";
import { Spin } from "antd";
import { componentPaths } from "../../../constants/path-constants";

// Style
import {
  loginBySalt,
  createLoadingSelector,
  resetLoginDataAndStore,
  resetStoreState,
  createErrorMessageSelector,
} from "../../../redux/actions/index";
import { connect } from "react-redux";
import {
  LOGIN_BY_SALT,
  LOGIN_BY_SALT_RESET,
} from "../../../redux/actions/type";
import history from "../../../common/history";
import {
  isObjectNotEmpty,
  isUserLoggedIn,
} from "../../../common/common-methods";
import { saltTypes } from "../../../constants";

class LoginBySaltView extends React.Component {
  constructor() {
    super();
    this.state = {
      isModalVisible: false,
      isShowingConnectRegistration: false,
      socialAccountDetails: { name: "", type: "", id: "" },
    };
  }

  /**
   * Default lifecycle method - componentDidUpdate
   * @param {*} nextProps The props to be passed from this component
   */

  /**
   * @return {*} The Login form
   */

  componentDidMount() {
    this.props.resetLoginDataAndStore();
    this.props.loginBySalt(
      this.props.match.params.saltType,
      this.props.match.params.saltValue
    );
  }

  componentWillUnmount() {}

  componentDidUpdate() {
    if (isObjectNotEmpty(this.props.errorMessage)) {
      history.push(componentPaths.login);
    }

    if (isObjectNotEmpty(this.props.loginBySaltResponse) && isUserLoggedIn()) {
      var redirectURL = componentPaths.dashboard;
      switch (this.props.match.params.saltType) {
        case saltTypes.ARTWORK:
        case saltTypes.PAYMENT_AUTHORIZE:
        case saltTypes.MULTIORDER:
        case saltTypes.MISSING_ARTWORK:
          redirectURL =
            componentPaths.orderdetails(
              this.props.loginBySaltResponse.order_id
            ) + `/${this.props.match.params.saltType}`;
          break;
        case saltTypes.STITCH_APPROVALS:
          redirectURL = componentPaths.STITCH_APPROVALS;
          break;
        case saltTypes.INVOICE_LIST:
          redirectURL = componentPaths.paybills;
          break;
        case saltTypes.HOME:
          redirectURL = componentPaths.dashboard;
          break;

        default:
          break;
      }

      history.push(redirectURL);
      this.props.resetStoreState(LOGIN_BY_SALT_RESET);
    }
  }

  render() {
    return (
      <Spin
        spinning={this.props.isFetchingData}
        size="large"
        className="mx-auto"
      >
        {" "}
      </Spin>
    );
  }
}

const loadingSelector = createLoadingSelector([LOGIN_BY_SALT]);
const errorMessageSelector = createErrorMessageSelector([LOGIN_BY_SALT]);

const mapStateToProps = (state) => ({
  isFetchingData: loadingSelector(state),
  loginBySaltResponse: state.loginBySaltResponse,
  errorMessage: errorMessageSelector(state),
});

export default connect(mapStateToProps, {
  loginBySalt,
  resetLoginDataAndStore,
  resetStoreState,
})(LoginBySaltView);
